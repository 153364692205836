<template>
  <div id="app">
    <VideoBg :sources="sources" :muted="muted"/>
    <div class="download-box">
      <div style="display: flex;flex-direction: column">
        <!--        <div style="display: flex;flex-direction: row-reverse">-->
        <!--          <button id="mute-btn" @click="toggleMute">-->
        <!--            <img :src="muted ? '/icons/mute-on.svg' : '/icons/mute-off.svg' "-->
        <!--                 alt="">-->
        <!--          </button>-->
        <!--        </div>-->
        <div class="button-containers">
          <div id="media-container">
            <button class="image-btn" v-if="!isPhone" @click="openLink('https://www.linkedin.com/in/jordanrhys')">
              <img class="btn-icon" src="/icons/linkedin.svg" alt="">
            </button>
            <button class="image-btn" v-if="!isPhone" @click="openLink('https://www.instagram.com/jordanlewisdesign/')">
              <img class="btn-icon" src="/icons/instagram.svg" alt="">
            </button>
            <button id="download-portfolio" class="btn" @click="downloadPortfolio">Download Portfolio</button>
          </div>
          <button id="schedule-call" class="btn" @click="scheduleCall">Schedule a Call</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VideoBg from './components/VideoBg.vue'

export default {
  name: 'App',
  components: {
    VideoBg
  },
  data() {
    return {
      sources: [
        {
          type: 'portrait',
          file: 'portrait.mp4'
        },
        {
          type: 'landscape',
          file: 'landscape.mp4'
        }
      ],
      windowWidth: window.innerWidth,
      muted: true
    }
  },
  computed: {
    isPhone() {
      return this.windowWidth <= 640
    }
  },
  methods: {
    downloadPortfolio() {
      window.open("/portfolio.pdf")
    },
    scheduleCall() {
      window.location = 'https://calendly.com/jordanlewisdesign/15min';
    },
    onWindowResized() {
      this.windowWidth = window.innerWidth
    },
    openLink(link) {
      window.open(link);
    },
    toggleMute() {
      this.muted = !this.muted
    }
  },
  created() {
    window.addEventListener("resize", this.onWindowResized);
  },
  destroyed() {
    window.removeEventListener("resize", this.onWindowResized);
  }

}
</script>

<style>
body {
  margin: 0 !important;
}

#media-container {
  display: flex;
  justify-content: space-between;
}

#download-portfolio {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  cursor: pointer;
}

#schedule-call {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  cursor: pointer;
}

.button-containers {
  margin: 0 1.5rem 1.5rem 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
}

.btn {
  margin-left: 1rem;
  color: #0027ff;
  border: 4px solid #f5f5f6;
  background-color: #00ffff;
  padding: 0.6rem 0;
  font-weight: bold;
}

.image-btn {
  margin-left: 1rem;
  color: #0027ff;
  border: 4px solid #f5f5f6;
  background-color: #00ffff;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.btn-icon {
  background: transparent;
  padding: 0 0.3rem;
}

#app {
  position: relative;
}

.download-box {
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 0;
  bottom: 0;
  /*height: 60px;*/
  width: 100%;
}

.btn-primary {
  background-color: transparent;
  border: 2px solid deeppink;
  color: deeppink;
}

.btn-primary:hover {
  background-color: deeppink;
  border: 2px solid transparent;
  color: #ffffff;
}

.btn-secondary {
  background-color: transparent;
  border: 2px solid mediumslateblue;
  color: mediumslateblue;
}

.btn-secondary:hover {
  background-color: mediumslateblue;
  border: 2px solid transparent;
  color: #ffffff;
}

#mute-btn {
  width: 50px;
  height: 50px;
  margin-right: 1.5rem;
  margin-bottom: 1rem;
  border-radius: 50%;

  color: #0027ff;
  border: 4px solid #f5f5f6;
  background-color: #00ffff;
  cursor: pointer;
}
</style>
