<template>
  <div class="VideoBg">
    <video playsinline autoplay loop :muted="muted" ref="video">
      <source
          :src="video.file"
          :type="video.type"
      >
    </video>
    <div class="VideoBg__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sources: {
      type: Array,
      required: true
    },
    img: {
      type: String
    },
    muted: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      allSizes: [],
      current: '',
      videoRatio: null,
      video: {
        type: null,
        file: null
      },
      elapsedTime: 0
    }
  },

  mounted() {
    this.sources.forEach(item => {
      this.allSizes.push(item.type)
    })
    this.changeVideoBasedOnScreenSize()
    this.setImageUrl()
    this.setContainerHeight()
    if (this.videoCanPlay()) {
      this.$refs.video.oncanplay = () => {
        if (!this.$refs.video) return
        this.videoRatio = this.$refs.video.videoWidth / this.$refs.video.videoHeight
        this.setVideoSize()
        this.$refs.video.style.visibility = 'visible'
      }
    }
    window.addEventListener('resize', this.resize)
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.resize)
  },

  methods: {
    setVideo(fileName = null) {
      this.elapsedTime = this.$refs.video.currentTime;
      if (fileName) {
        this.video.file = require('@/assets/videos/' + fileName)
        this.video.type = this.getMediaType(fileName)
        this.$refs.video.load()
      } else {
        this.video.file = require('@/assets/videos/' + this.sources[0].file)
        this.video.type = this.getMediaType(this.sources[0].file)
        this.current = this.sources[0].type
      }
      this.$refs.video.currentTime = this.elapsedTime;
    },

    resize() {
      this.$refs.video.cu = 10;
      this.setContainerHeight()
      this.changeVideoBasedOnScreenSize()
      if (this.videoCanPlay()) {
        this.setVideoSize()
      }
    },

    changeVideoBasedOnScreenSize() {
      const screenSize = window.innerWidth
      if (
          screenSize <= 640 &&
          this.allSizes.includes('portrait') &&
          this.current !== 'portrait'
      ) {
        this.changeVideo('portrait')
        return
      }

      if (
          640 < screenSize &&
          screenSize < 900 &&
          this.allSizes.includes('portrait') &&
          this.current !== 'portrait'
      ) {
        this.changeVideo('portrait')
        return
      }

      if (
          screenSize >= 900 &&
          this.allSizes.includes('landscape') &&
          this.current !== 'landscape'
      ) {
        this.changeVideo('landscape')
      }
    },

    changeVideo(type = '') {
      this.current = type
      this.sources.forEach(item => {
        if (item.type === type) {
          this.setVideo(item.file)
        }
      })
    },

    videoCanPlay() {
      return !!this.$refs.video.canPlayType
    },

    setImageUrl() {
      if (this.img) {
        this.$el.style.backgroundImage = `url(${this.img})`
      }
    },

    setContainerHeight() {
      this.$el.style.height = `${window.innerHeight}px`
    },

    setVideoSize() {
      let width, height, containerRatio = this.$el.offsetWidth / this.$el.offsetHeight
      if (containerRatio > this.videoRatio) {
        width = this.$el.offsetWidth
      } else {
        height = this.$el.offsetHeight
      }
      this.$refs.video.style.width = width ? `${width}px` : 'auto'
      this.$refs.video.style.height = height ? `${height}px` : 'auto'
    },

    getMediaType(src) {
      return 'video/' + src.split('.').pop()
    }
  }
}
</script>

<style scoped>
.VideoBg {
  position: relative;
  background-size: cover;
  background-position: center;
  overflow: scroll;
}

.VideoBg video {
  position: fixed;
  top: 50%;
  left: 50%;
  visibility: hidden;
  transform: translate(-50%, -50%);
}

.VideoBg__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>